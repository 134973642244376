import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StoreService } from 'src/app/_services/store.service';

@Component({
  selector: 'app-update-gmb-location-dialog',
  templateUrl: './update-gmb-location-dialog.component.html',
  styleUrls: ['./update-gmb-location-dialog.component.scss']
})
export class UpdateGmbLocationDialogComponent {
  addGmbLocation: FormGroup;
  storeId: string;
  rowId: any;
  isLoading = false;
  errorShow: any = '';
  gmbLocationList: any[] = []; 
  searchControl1: any = '';
  constructor(
    public fb: FormBuilder,
    private storeService: StoreService,
    public dialogRef: MatDialogRef<UpdateGmbLocationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
    this.getGMBLocationData();
    this.storeId = localStorage.getItem("currentStore");
    this.addGmbLocation = this.fb.group({
      store_id: [this.storeId],
      location_id: [""],
      location_search:[''],
    });
  }


  clearSearch() {
    setTimeout(() => {
      this.searchControl1 = '';
    }, 1000);
  }


  getGMBLocationData() {
    this.storeService.getGMBLocationData().subscribe((data: any) => {
      this.gmbLocationList = data.data;
    })
  }


  addGmbLocationSubmit() {
    const validationForm = this.addGmbLocation.status;
    if (validationForm == "VALID") {
      this.isLoading = true;
      let keySend = {
        store_id: this.storeId,
        location_id: this.addGmbLocation.value.location_id
      }
      this.storeService.updateGMBLocation(keySend).subscribe((data: any) => {
        this.isLoading = false;
        if (!data.error) {
          this.dialogRef.close(1);
        } else {
          this.errorShow = data.message || 'An error occurred.';
        }
      },
        (error) => {
          this.isLoading = false;
          this.errorShow = 'Failed to this link. Please try again.';
          console.error(error);
        }
      );
    }
  }
}
