<span>
    <form name="addGmbLocation" [formGroup]="addGmbLocation" (ngSubmit)="addGmbLocationSubmit()" class="fixActionRow">
        <h1 mat-dialog-title>Select the GMB location</h1>
        <div mat-dialog-content style="overflow: hidden;">
            <div fxLayout="row" class="!mt-4" style="width: 100%;">
                <mat-form-field appearance="fill" style="width: 100%;">
                    <mat-label>Select the GMB location</mat-label>
                    <mat-select formControlName="location_id">
                        <div class="search-filter-input">
                            <mat-form-field appearance="fill" fxFlex="100" style="padding:10px;">
                                <mat-label>Search for GMB location</mat-label>
                                <input matInput [(ngModel)]="searchControl1" autocomplete="off"
                                    formControlName="location_search" (keydown)="$event.stopPropagation()" (focusout)="clearSearch()">
                                <mat-icon class="admin-icons" matPrefix>search</mat-icon>
                            </mat-form-field>
                        </div>
                        <mat-option *ngFor="let data of gmbLocationList | filter1:searchControl1" [value]="data.id">
                            {{ data.title }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
        <mat-dialog-actions align="end" class="button-box-right form-row">
            <button mat-button mat-dialog-close mat-stroked-button>Cancel</button>
            <button mat-button mat-flat-button color="primary" class="apply-btn-colr">Submit</button>
        </mat-dialog-actions>
    </form>
</span>