import { Component, Inject, OnInit } from '@angular/core';
import { StoreService } from 'src/app/_services/store.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MenuServiceV2 } from 'src/app/_services/menu-v2.service';

@Component({
  selector: 'app-gmb-logo-upload',
  templateUrl: './gmb-logo-upload.component.html',
  styleUrls: ['./gmb-logo-upload.component.scss'],
})
export class GmbLogoUploadComponent implements OnInit {
  store_id: string | null = null;
  uploadImage: File | null = null;
  isLoading: boolean = false;
  ImgErrorShow: string = '';
  readonly maxFileSize = 10 * 1024; // 10 KB

  constructor(
    private storeService: StoreService,
    private menuService: MenuServiceV2,
    public dialogRef: MatDialogRef<GmbLogoUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.store_id = localStorage.getItem('currentStore');
  }

  onImageSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      if (!file.type.startsWith('image/')) {
        this.ImgErrorShow = 'Please select a valid image file.';
        this.uploadImage = null;
        return;
      }

      if (file.size <= this.maxFileSize) {
        this.ImgErrorShow = `File size exceeds the maximum limit of 10 KB.`;
        this.uploadImage = null;
        return;
      }

      this.uploadImage = file;
      this.ImgErrorShow = '';
    }
  }

  onCoverSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      if (!file.type.startsWith('image/')) {
        this.ImgErrorShow = 'Please select a valid image file.';
        this.uploadImage = null;
        return;
      }

      // if (file.size <= this.maxFileSize) {
      //   this.ImgErrorShow = `File size exceeds the maximum limit of 10 KB.`;
      //   this.uploadImage = null;
      //   return;
      // }

      // this.uploadImage = file;
      // this.ImgErrorShow = '';

      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const minWidth = 480;
          const minHeight = 270;
          const maxWidth = 2120;
          const maxHeight = 1192;

          if (img.width < minWidth || img.height < minHeight) {
            this.ImgErrorShow = `Image too small. Got: ${img.width}px/${img.height}px (min: ${minWidth}px/${minHeight}px w/h).`;
            this.uploadImage = null;
            return;
          }

          if (img.width > maxWidth || img.height > maxHeight) {
            this.ImgErrorShow = `Image too large. Got: ${img.width}px/${img.height}px (max: ${maxWidth}px/${maxHeight}px w/h).`;
            this.uploadImage = null;
            return;
          }

          this.uploadImage = file;
          this.ImgErrorShow = '';
        };

        img.src = e.target?.result as string;
      };

      reader.readAsDataURL(file);
    }
  }

  giftImageUpload(): void {
    if (!this.uploadImage) {
      this.ImgErrorShow = 'Please select an image before uploading.';
      return;
    }

    this.isLoading = true;

    const formData = new FormData();
    formData.append(
      this.data.img_value === 'profile' ? 'logo' : 'cover',
      this.uploadImage,
      this.uploadImage.name
    );
    formData.append('store_id', this.store_id || '');
    formData.append(
      'image_status',
      this.data.img_value === 'profile' ? 'logo' : 'cover'
    );

    this.storeService.gmbUpdateLogo(formData).subscribe(
      (response: any) => {
        this.isLoading = false;

        if (!response.error) {
          this.dialogRef.close(1);
        } else {
          this.ImgErrorShow = response.message || 'An error occurred.';
        }
      },
      (error) => {
        this.isLoading = false;
        this.ImgErrorShow = 'Failed to upload the image. Please try again.';
        console.error(error);
      }
    );
  }
}
