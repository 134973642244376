<!-- <div class="!p-5">
  <div>
    <button mat-button type="button" (click)="goBack()" class="go-back rounded-md text-lg font-medium !p-1.5"
      tabindex="-1">Go Back</button>
  </div>
  <div class="flex flex-col items-center xl:!mx-[12%] 2xl:!mx-[15%] !pt-2.5">
    <div *ngIf="isUserLoading === 1">
      <p class="mt-2">Please wait for login while users data is loading...</p>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div *ngIf="isUserLoading === 3">
      <p>An issue occurred while loading users data. Please reload the page.</p>
    </div>
    <div class="grid grid-cols-4 gap-4 !mb-5 items-center text-center">
      <div class="heading">
        <p class="text-xl">Select Station</p>
      </div>
      <div class="pin-display col-span-3">
        <input type="text" class="w-full bg-gray-100 text-center h-14 text-6xl xs:!text-4xl rounded hidden-text"
          [ngModel]="result" readonly />
      </div>
    </div>
    <span *ngIf="!isMobile">
      <div class="grid grid-cols-4 xs:grid-cols-3 gap-4 xs:gap-2">
        <div class="terminal-list xs:contents">
          <button class="border border-gray-300 !mb-2 w-full h-14 vxs:!h-20 xs:!text-base xs:!p-1 rounded-md" [ngClass]="{'lab-bg-blue text-white': selectedTerminal == item.id,
          'text-black': selectedTerminal != item.id}" (click)="setTerminal(item)"
            [disabled]="isTerminalActive(item.id)" *ngFor="let item of terminalList">{{ item.name }}</button>
          <button class="border border-gray-300 !mb-2 w-full h-14 vxs:!h-20 rounded-md invisible"
            *ngIf="terminalList.length === 1">-------------------</button>
        </div>
        <div class="numpad col-span-2">
          <div class="grid grid-cols-3 gap-2">
            <input matRipple type="button" name="" [value]="i+1"
              class="border text-4xl drop-shadow-md rounded-md hover:bg-gray-100 disable-dbl-tap-zoom"
              (click)="pressNum(i+1)" *ngFor="let num of [].constructor(10) | slice:1; let i = index" />
            <input matRipple type="button" name="" value="0"
              class="border text-4xl drop-shadow-md rounded-md hover:bg-gray-100 disable-dbl-tap-zoom"
              (click)="pressNum('0')" />
            <input matRipple type="button" name="" value="C"
              class="border text-4xl drop-shadow-md rounded-md hover:bg-gray-100 disable-dbl-tap-zoom"
              (click)="allClear()" />
            <button class="border text-4xl drop-shadow-md rounded-md hover:bg-gray-100 disable-dbl-tap-zoom"
              (click)="clear()">
              <span class="flex justify-center">
                <svg width="36" height="28" viewBox="0 0 36 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M33 0.5H10.5C9.465 0.5 8.655 1.025 8.115 1.82L0 14L8.115 26.165C8.655 26.96 9.465 27.5 10.5 27.5H33C34.65 27.5 36 26.15 36 24.5V3.5C36 1.85 34.65 0.5 33 0.5ZM33 24.5H10.605L3.6 14L10.59 3.5H33V24.5ZM15.615 21.5L21 16.115L26.385 21.5L28.5 19.385L23.115 14L28.5 8.615L26.385 6.5L21 11.885L15.615 6.5L13.5 8.615L18.885 14L13.5 19.385L15.615 21.5Z"
                    fill="#636363" />
                </svg>
              </span>
            </button>
          </div>
        </div>
        <div class="login grid grid-cols-1 grid-rows-4 gap-2 ">
          <button [disabled]="input?.length < 4 || !selectedTerminal" (click)="logInCheckDrawer()"
            class="global3 loginBtn drop-shadow-md" (keyup)="errorMessage = false">
            Login
          </button>
          <label *ngIf="errorMessage" class="text-danger fs-13 font-weight-bold" translate>
            Invalid pin
          </label>
          <div *ngIf="!errorMessage"></div>
          <div></div>
          <button class="border rounded-md border-gray-100 text-4xl xs:text-2xl drop-shadow-md hover:bg-gray-100"
            (click)="onNoClick()">Cancel</button>
        </div>
      </div>
    </span>
    <span *ngIf="isMobile">
      <div class="grid grid-cols-4 xs:grid-cols-3 gap-4 xs:gap-2">
        <div class="terminal-list xs:contents">
          <button class="border border-gray-300 !mb-2 w-full h-14 vxs:!h-20 xs:!text-base xs:!p-1 rounded-md" [ngClass]="{'lab-bg-blue text-white': selectedTerminal == item.id,
          'text-black': selectedTerminal != item.id}" (click)="setTerminal(item)"
            [disabled]="isTerminalActive(item.id)" *ngFor="let item of terminalList">{{ item.name }}</button>
          <button class="border border-gray-300 !mb-2 w-full h-14 vxs:!h-20 rounded-md invisible"
            *ngIf="terminalList.length === 1">-------------------</button>
        </div>
      </div>
      <div class="grid grid-cols-4 xs:grid-cols-3 gap-4 xs:gap-2">
        <div class="numpad col-span-2">
          <div class="grid grid-cols-3 gap-2">
            <input matRipple type="button" name="" [value]="i+1"
              class="border text-4xl drop-shadow-md rounded-md hover:bg-gray-100 disable-dbl-tap-zoom"
              (click)="pressNum(i+1)" *ngFor="let num of [].constructor(10) | slice:1; let i = index" />
            <input matRipple type="button" name="" value="0"
              class="border text-4xl drop-shadow-md rounded-md hover:bg-gray-100 disable-dbl-tap-zoom"
              (click)="pressNum('0')" />
            <input matRipple type="button" name="" value="C"
              class="border text-4xl drop-shadow-md rounded-md hover:bg-gray-100 disable-dbl-tap-zoom"
              (click)="allClear()" />
            <button class="border text-4xl drop-shadow-md rounded-md hover:bg-gray-100 disable-dbl-tap-zoom"
              (click)="clear()">
              <span class="flex justify-center">
                <svg width="36" height="28" viewBox="0 0 36 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M33 0.5H10.5C9.465 0.5 8.655 1.025 8.115 1.82L0 14L8.115 26.165C8.655 26.96 9.465 27.5 10.5 27.5H33C34.65 27.5 36 26.15 36 24.5V3.5C36 1.85 34.65 0.5 33 0.5ZM33 24.5H10.605L3.6 14L10.59 3.5H33V24.5ZM15.615 21.5L21 16.115L26.385 21.5L28.5 19.385L23.115 14L28.5 8.615L26.385 6.5L21 11.885L15.615 6.5L13.5 8.615L18.885 14L13.5 19.385L15.615 21.5Z"
                    fill="#636363" />
                </svg>
              </span>
            </button>
          </div>
        </div>
        <div class="login grid grid-cols-1 grid-rows-4 gap-2 ">
          <button [disabled]="input?.length < 4 || !selectedTerminal" (click)="logInCheckDrawer()"
            class="global3 loginBtn drop-shadow-md" (keyup)="errorMessage = false">
            Login
          </button>
          <label *ngIf="errorMessage" class="text-danger fs-13 font-weight-bold" translate>
            Invalid pin
          </label>
          <div *ngIf="!errorMessage"></div>
          <div></div>
          <button class="border rounded-md border-gray-100 text-4xl xs:text-2xl drop-shadow-md hover:bg-gray-100"
            (click)="onNoClick()">Cancel</button>
        </div>
      </div>
    </span>
  </div>
</div> -->

<div class="pos-dark-mode h-full" id="darkmode">
  <div mat-dialog-content class="container xs:!pb-10 xl:!px-[14%] 2xl:!px-[11%] vxs:!p-2 overflow-auto drk-bg-color">
    <div>
      <button mat-button type="button" (click)="goBack()" class="go-back rounded-md text-lg font-medium !p-1.5"
        tabindex="-1">Go Back</button>
    </div>
    <div [class.hidden]="isUserLoading !== 1" class="margin-b text-center">
      <p class="mt-2">Please wait for login while users data is loading...</p>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div *ngIf="isLoading" class="margin-b text-center !mt-2">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div *ngIf="isUserLoading === 3" class="margin-b text-center">
      <p>An issue occurred while loading users data. Please reload the page.</p>
    </div>
    <div class="grid grid-cols-4 gap-4 !mb-5 items-center !pt-2.5 text-center">
      <div class="heading">
        <p class="text-xl text-clr">Select Station</p>
      </div>
      <div class="pin-display col-span-3">
        <input type="text" class="w-full bg-gray-100 text-center h-14 text-6xl xs:!text-4xl rounded hidden-text"
          [ngModel]="result" readonly />
      </div>
    </div>
    <span *ngIf="!isMobile">
      <div class="grid grid-cols-4 xs:grid-cols-3 gap-4 xs:gap-2">
        <div class="terminal-list xs:contents">
          <button class="border border-gray-300 !mb-2 w-full h-14 vxs:!h-20 xs:!text-base xs:!p-1 rounded-md text-clr"
            [ngClass]="{'lab-bg-blue text-white': selectedTerminal == item.id,
          'text-black': selectedTerminal != item.id}" (click)="setTerminal(item)"
            [disabled]="isTerminalActive(item.id)" *ngFor="let item of terminalList">{{ item.name }}</button>
          <button class="border border-gray-300 !mb-2 w-full h-14 vxs:!h-20 rounded-md invisible"
            *ngIf="terminalList.length === 1">-------------------</button>
        </div>
        <div class="numpad col-span-2 min-h-[200px] flex items-center justify-center">
          <div class="grid grid-cols-3 gap-2 text-clr w-full">
            <input type="button" name="" [value]="num"
              class="w-full border text-4xl drop-shadow-md rounded-md hover:bg-gray-100 active:bg-gray-200 transition-all duration-150 ease-in-out disable-dbl-tap-zoom"
              (click)="pressNum(num)" *ngFor="let num of keypadKeys; trackBy: trackByKey" />

            <input type="button" name="" value="0"
              class="border text-4xl drop-shadow-md rounded-md hover:bg-gray-100 active:bg-gray-200 transition-all duration-150 ease-in-out disable-dbl-tap-zoom"
              (click)="pressNum('0')" />

            <input type="button" name="" value="C"
              class="border text-4xl drop-shadow-md rounded-md hover:bg-gray-100 active:bg-gray-200 transition-all duration-150 ease-in-out disable-dbl-tap-zoom"
              (click)="allClear()" />

            <button
              class="border text-4xl drop-shadow-md rounded-md hover:bg-gray-100 active:bg-gray-200 transition-all duration-150 ease-in-out disable-dbl-tap-zoom"
              (click)="clear()">
              <span class="flex justify-center">
                <svg width="36" height="28" viewBox="0 0 36 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M33 0.5H10.5C9.465 0.5 8.655 1.025 8.115 1.82L0 14L8.115 26.165C8.655 26.96 9.465 27.5 10.5 27.5H33C34.65 27.5 36 26.15 36 24.5V3.5C36 1.85 34.65 0.5 33 0.5ZM33 24.5H10.605L3.6 14L10.59 3.5H33V24.5ZM15.615 21.5L21 16.115L26.385 21.5L28.5 19.385L23.115 14L28.5 8.615L26.385 6.5L21 11.885L15.615 6.5L13.5 8.615L18.885 14L13.5 19.385L15.615 21.5Z"
                    fill="#636363" />
                </svg>
              </span>
            </button>
          </div>
        </div>
        <div class="login grid grid-cols-1 grid-rows-4 gap-2 ">
          <button [disabled]="input?.length < 4 || !selectedTerminal" (click)="logInCheckDrawer()"
            class="global3 loginBtn drop-shadow-md" (keyup)="errorMessage = false">
            Login
          </button>
          <label *ngIf="errorMessage" class="text-danger fs-13 font-weight-bold" translate>
            Invalid pin
          </label>
          <div *ngIf="!errorMessage"></div>
          <div></div>
          <button
            class="border rounded-md border-gray-100 text-4xl xs:text-2xl drop-shadow-md hover:bg-gray-100 text-clr cncel-butn"
            (click)="onNoClick()">Cancel</button>
        </div>
      </div>
    </span>

    <span *ngIf="isMobile">
      <div class="grid grid-cols-4 xs:grid-cols-3 gap-4 xs:gap-2">
        <div class="terminal-list xs:contents">
          <button class="border border-gray-300 !mb-2 w-full h-14 vxs:!h-20 xs:!text-base xs:!p-1 rounded-md text-clr"
            [ngClass]="{'lab-bg-blue text-white': selectedTerminal == item.id, 'text-black': selectedTerminal != item.id}"
            (click)="setTerminal(item)" [disabled]="isTerminalActive(item.id)"
            *ngFor="let item of terminalList; trackBy: trackByIndex">
            {{ item.name }}
          </button>
          <button class="border border-gray-300 !mb-2 w-full h-14 vxs:!h-20 rounded-md invisible"
            *ngIf="terminalList.length === 1">-------------------</button>
        </div>
      </div>

      <div class="grid grid-cols-4 xs:grid-cols-3 gap-4 xs:gap-2">
        <div class="numpad col-span-2 min-h-[200px] flex items-center justify-center overflow-auto">
          <div class="grid grid-cols-3 gap-3 text-clr w-full">
            <input type="button" name="" [value]="num"
              class="w-full border text-4xl rounded-md hover:bg-gray-100 active:bg-gray-200 transition-all duration-150 ease-in-out disable-dbl-tap-zoom"
              (click)="pressNum(num)" *ngFor="let num of keypadKeys; trackBy: trackByKey" />

            <input type="button" name="" value="0"
              class="w-full border text-4xl rounded-md hover:bg-gray-100 active:bg-gray-200 transition-all duration-150 ease-in-out disable-dbl-tap-zoom"
              (click)="pressNum('0')" />

            <input type="button" name="" value="C"
              class="w-full border text-4xl rounded-md hover:bg-gray-100 active:bg-gray-200 transition-all duration-150 ease-in-out disable-dbl-tap-zoom"
              (click)="allClear()" />

            <button
              class="w-full border text-4xl rounded-md hover:bg-gray-100 active:bg-gray-200 transition-all duration-150 ease-in-out disable-dbl-tap-zoom"
              (click)="clear()">
              <span class="flex justify-center">
                <svg width="36" height="28" viewBox="0 0 36 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M33 0.5H10.5C9.465 0.5 8.655 1.025 8.115 1.82L0 14L8.115 26.165C8.655 26.96 9.465 27.5 10.5 27.5H33C34.65 27.5 36 26.15 36 24.5V3.5C36 1.85 34.65 0.5 33 0.5ZM33 24.5H10.605L3.6 14L10.59 3.5H33V24.5ZM15.615 21.5L21 16.115L26.385 21.5L28.5 19.385L23.115 14L28.5 8.615L26.385 6.5L21 11.885L15.615 6.5L13.5 8.615L18.885 14L13.5 19.385L15.615 21.5Z"
                    fill="#636363" />
                </svg>
              </span>
            </button>
          </div>
        </div>

        <div class="login grid grid-cols-1 grid-rows-4 gap-2">
          <button [disabled]="input?.length < 4 || !selectedTerminal" (click)="logInCheckDrawer()"
            class="global3 loginBtn drop-shadow-md" (keyup)="errorMessage = false">
            Login
          </button>

          <label *ngIf="errorMessage" class="text-danger fs-13 font-weight-bold" translate>
            Invalid pin
          </label>

          <div *ngIf="!errorMessage"></div>
          <div></div>

          <button
            class="border rounded-md border-gray-100 text-4xl xs:text-2xl drop-shadow-md hover:bg-gray-100 text-clr cncel-butn"
            (click)="onNoClick()">
            Cancel
          </button>
        </div>
      </div>
    </span>
  </div>
</div>