import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Store } from 'src/app/_models/store';
import { StoreService } from 'src/app/_services/store.service';
import { MenuServiceV2 } from 'src/app/_services/menu-v2.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-menu-category',
  templateUrl: './menu-category.component.html',
  styleUrls: ['./menu-category.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class MenuCategoryComponent implements OnInit {
  store: Store;
  isLoading: boolean = true;
  @Output() refresh = new EventEmitter();
  displayedColumns = ['name', 'star'];
  menuGroupsOrdered;
  menuGroupOrder;

  searchTerm: string;
  private searchModelChanged: Subject<string[]> = new Subject<string[]>();
  private searchSub: Subscription;
  debounceTime = 500;
  searchResults;
  availabilityFilter;
  isTouchDevice: boolean;
  errorShow: any = '';
  gmbLocationGet: boolean;
  constructor(private menuService: MenuServiceV2, public storeService: StoreService, private cdr: ChangeDetectorRef, public snackBar: MatSnackBar,) {
    this.isTouchDevice = this.checkIfTouchDevice();
  }

  checkIfTouchDevice(): boolean {
    return ('ontouchstart' in window || navigator.maxTouchPoints > 0);
  }

  ngOnInit() {
    this.menuGroupOrder = []
    this.storeService.current.subscribe(store => {
      if (store) {
        this.store = store;
        this.menuGroupReset();
      }
    })

    this.searchSub = this.searchModelChanged
      .pipe(debounceTime(this.debounceTime),)
      .subscribe(() => {
        this.searchMenuItem();
      });
  }

  ngOnDestroy(): void {
    this.searchSub.unsubscribe();
  }

  search(e) {
    console.log(e);
    this.searchModelChanged.next(e);
  }

  searchMenuItem() {
    console.log(this.searchTerm, this.availabilityFilter);
    this.menuService.searchMenuItem(this.store.id, this.searchTerm || '',
      this.availabilityFilter).subscribe(searchResults => {
        console.log(searchResults);
        this.searchResults = searchResults;
      });
  }

  menuGroupReset() {
    this.isLoading = true;
    this.menuService.getStoreMenuGroups(this.store.id).subscribe(data => {
      this.menuGroupsOrdered = data
      if(data != '' || data != undefined){
        this.gmbLocationGet = data[0].gmb_location
      }
      this.menuGroupsOrdered.forEach(mg => this.menuGroupOrder.push(mg.id));
      console.log(this.menuGroupOrder);
      this.isLoading = false;
    })
  }

  menuSyncGmb() {
    this.errorShow = '';
    this.isLoading = true;
    this.cdr.detectChanges();
    let keySend = {
      store_id: this.store.id
    }
    this.menuService.menuSyncGmb(keySend).subscribe((data: any) => {
      if (!data.error) {
        this.snackBar.open('Menu successfully synced', 'OK', { duration: 2000 });
      } else {
        this.errorShow = data.message;
      }
      this.isLoading = false;
      this.cdr.detectChanges();
    })
  }

  setMenuGroup(event) {
    if (event) {
      console.log(event);
      let mg = this.menuGroupsOrdered.find(m => m.id === event.id);
      mg.name = event.name;
      mg.subgroup = event.subgroup;
    }
  }

  swap(array, ci, ni) {
    [array[ci], array[ni]] = [array[ni], array[ci]]
  }

  updateOrder(event) {
    console.log(event);

    this.moveMenuGroup(event.direction, event.id);
  }

  moveMenuGroup(direction, id) {
    console.log(this.menuGroupOrder, direction, id);

    const currentIndex = this.menuGroupOrder.indexOf(id);
    switch (direction) {
      case 'top': {
        this.menuGroupOrder.unshift(this.menuGroupOrder.splice(currentIndex, 1)[0]);
        break;
      }
      case 'up': {
        const newIndex = currentIndex - 1;
        this.swap(this.menuGroupOrder, currentIndex, newIndex);
        break;
      }
      case 'down': {
        const newIndex = currentIndex + 1;
        this.swap(this.menuGroupOrder, currentIndex, newIndex);
        break;
      }
      case 'bottom': {
        this.menuGroupOrder.push(this.menuGroupOrder.splice(currentIndex, 1)[0]);
        break;
      }
    }
    this.menuService.updateOrder(this.store.id, JSON.stringify({ 'type': 'menu_group', 'order': this.menuGroupOrder })).subscribe(data => {
      this.ngOnInit();
    })
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }

    let a = event.container.data;
    let mgo = [];
    a.map((mg: any) => {
      mgo.push(mg.id);
    });
    this.menuService.updateOrder(this.store.id, JSON.stringify({ 'type': 'menu_group', 'order': mgo })).subscribe(data => {
      this.ngOnInit();
    })
  }
}
