import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StoreService } from 'src/app/_services/store.service';

@Component({
  selector: 'app-order-online-link',
  templateUrl: './order-online-link.component.html',
  styleUrls: ['./order-online-link.component.scss']
})
export class OrderOnlineLinkComponent {
  addgmbOrderLink: FormGroup;
  storeId: string;
  file: File;
  addEditUpdate: any;
  editgmbOrderLink: FormGroup;
  rowId: any;
  isLoading = false;
  errorShow: any = '';
  constructor(
    public fb: FormBuilder,
    private storeService: StoreService,
    public dialogRef: MatDialogRef<OrderOnlineLinkComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log('dddddddd', data);

  }

  ngOnInit() {
    this.storeId = localStorage.getItem("currentStore");
    if (this.data.type == 'add') {
      this.addgmbOrderLink = this.fb.group({
        store_id: [this.storeId],
        placeActionType: ["", Validators.required],
        uri: ["", Validators.required],
        action: ['add'],
      });
    } else if (this.data.type == 'edit') {
      this.rowId = this.data.data.id;
      const editData = this.data.data;
      this.editgmbOrderLink = this.fb.group({
        store_id: [this.storeId],
        placeActionType: [editData.placeActionType, Validators.required],
        uri: [editData.uri],
        action: ['update'],
        link_id: [editData.name]
      });
    } else if (this.data.type == 'delete') {
      this.rowId = this.data.id;
    }
  }

  addgmbOrderLinkSubmit() {
    const validationForm = this.addgmbOrderLink.status;
    if (validationForm == "VALID") {
      this.isLoading = true;
      this.storeService.addgmbOnlineOrderLink(this.addgmbOrderLink.value).subscribe((data: any) => {
        this.isLoading = false;
        if (!data.error) {
          this.dialogRef.close(1);
        } else {
          this.errorShow = data.message || 'An error occurred.';
        }
      },
        (error) => {
          this.isLoading = false;
          this.errorShow = 'Failed to this link. Please try again.';
          console.error(error);
        }
      );
    }
  }
  editgmbOrderLinkSubmit() {
    const validationForm = this.editgmbOrderLink.status;
    if (validationForm == "VALID") {
      this.isLoading = true;
      this.storeService.addgmbOnlineOrderLink(this.editgmbOrderLink.value).subscribe((data: any) => {
        this.isLoading = false;
        if (!data.error) {
          this.dialogRef.close(1);
        } else {
          this.errorShow = data.message || 'An error occurred.';
        }
      },
        (error) => {
          this.isLoading = false;
          this.errorShow = 'Failed to this link. Please try again.';
          console.error(error);
        }
      );
    }
  }

  mediaDelete() {
    this.isLoading = true;
    let keySend = {
      store_id: this.storeId,
      link_id: this.rowId,
    }
    this.storeService.addgmbOnlineOrderLinkDelete(keySend).subscribe((data: any) => {
      this.isLoading = false;
      if (!data.error) {
        this.dialogRef.close(1);
      } else {
        this.errorShow = data.message || 'An error occurred.';
      }
    },
      (error) => {
        this.isLoading = false;
        this.errorShow = 'Failed to this link. Please try again.';
        console.error(error);
      }
    );
  }
}