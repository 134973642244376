<mat-toolbar color="primary" class="mat-elevation-z7" style="z-index:2;" [class.visible]="isFullScreen">
  <button mat-icon-button (click)="toggleSideNav(0)">
    <mat-icon>menu</mat-icon>
  </button>
  <a [routerLink]="['/home/dashboard']" routerLinkActive="is-active">
    <picture class="h-12">
      <source srcset="../../assets/logo_sm.jpg" media="(min-width: 300px) and (max-width: 576px)">
      <source srcset="../../assets/logo.jpg" media="(min-width: 576px)">
      <img srcset="../../assets/logo.jpg" alt="Labrador AI" height="50px" class="h-12">
    </picture>
  </a>
  <span class="app-spacer"></span>
  <div [formGroup]="toolbarForm">
    <input class="search" type="text" placeholder="Select Store" matInput [formControl]="filter"
      [matAutocomplete]="auto" [value]="selectedStoreName">
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="storeName">
      <mat-option *ngFor="let store of stores" [value]="store" (click)="storeSelected(store)">
        {{store.store_name}}
      </mat-option>
    </mat-autocomplete>
  </div>
  <button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="logout()">
      <mat-icon>power_settings_new</mat-icon>
      <span>Logout</span>
    </button>
    <button mat-menu-item (click)="openFullscreen()" *ngIf="!isFullScreen">
      <mat-icon>fullscreen</mat-icon>
      <span>Fullscreen</span>
    </button>
    <button mat-menu-item (click)="closeFullscreen()" *ngIf="isFullScreen">
      <mat-icon>fullscreen_exit</mat-icon>
      <span>Exit Fullscreen</span>
    </button>
  </mat-menu>
</mat-toolbar>

<mat-sidenav-container class="app-container xs:pt-[60px]" [class.visible]="isFullScreen">
  <mat-sidenav #snav mode="side" [class.snav-opened]="opened" [class.snav-closed]="!opened" opened disableClose>
    <div class="sidenav" [class.store-select-dot]="opened">
      <div class="flex flex-row justify-between items-center my-3">
        <button mat-icon-button (click)="toggleSideNav(0)" class="ml-2">
          <mat-icon>menu</mat-icon>
        </button>

        <div class="text-left w-full" *ngIf="opened">
          <p class="text-sm font-medium text-gray-700 group-hover:text-gray-900">{{selectedStoreName}}</p>
          <p class="text-xs font-medium text-gray-500 group-hover:text-gray-700">{{store?.address}}</p>
        </div>

        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="logout()">
            <mat-icon>power_settings_new</mat-icon>
            <span>Logout</span>
          </button>
          <button mat-menu-item (click)="openFullscreen()" *ngIf="!isFullScreen">
            <mat-icon>fullscreen</mat-icon>
            <span>Fullscreen</span>
          </button>
          <button mat-menu-item (click)="closeFullscreen()" *ngIf="isFullScreen">
            <mat-icon>fullscreen_exit</mat-icon>
            <span>Exit Fullscreen</span>
          </button>
        </mat-menu>
      </div>
      <div *ngIf="opened">
        <div [formGroup]="toolbarForm" class="flex flex-row items-center w-full justify-center">
          <mat-form-field class="w-full" appearance="fill" subscriptSizing="dynamic">
            <mat-label>Select Store</mat-label>
            <input class="search" type="text" placeholder="Select Store" matInput [formControl]="filter"
              [matAutocomplete]="auto" [value]="selectedStoreName" style="width: 163px;">
          </mat-form-field>
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="storeName">
            <mat-option *ngFor="let store of stores" [value]="store" (click)="storeSelected(store)">
              {{store.store_name}}
            </mat-option>
          </mat-autocomplete>
        </div>
      </div>

    </div>
    <div class="sidenav">
      <div class="flex flex-row overflow-x-hidden">
        <app-side-nav [opened]="opened" (click)="isSmallScreen() ? toggleSideNav(0) : dummy() "></app-side-nav>
      </div>

    </div>
  </mat-sidenav>

  <mat-sidenav-content [ngStyle]="{'margin-left.px': contentMargin}">
    <div class="basic-container smooth-scroll sm-33">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>


<span *ngIf="isOrdersView">
  <audio autoplay loop>
    <source src="assets/audio/Xbox 360 Power.mp3" type="audio/ogg">
  </audio>
</span>
