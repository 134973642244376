import {ApplicationRef, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  alertAddress: string = '';
  alertTitle: string = 'Delivery Out Of Range!';

  constructor(
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    private cdr: ChangeDetectorRef,
    private appRef: ApplicationRef,  // ✅ Inject ApplicationRef
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    console.log('Dialog Constructor:', this.data);
  }

  ngOnInit() {
    console.log('Dialog ngOnInit:', this.data);
    if (this.data?.title) {
      this.alertTitle = "Delivery Out Of Range!";
      this.alertAddress = `The address you entered '${this.data.title}' is outside of the current delivery area.`;

      setTimeout(() => {
        console.log('Forcing UI update with detectChanges and tick...');
        this.cdr.detectChanges();
        this.appRef.tick();
        document.dispatchEvent(new Event('click')); // ✅ Simulate user interaction
      }, 0);
    }
  }

  close(event: boolean) {
    this.dialogRef.close(event);
  }

  save(event: boolean) {
    this.dialogRef.close(event);
  }
}
