import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {Store} from 'src/app/_models/store';
import {merge, Subject} from 'rxjs';
import {OffersV2DataSource} from 'src/app/_datasources/offers-v2-datasource';
import {tap} from 'rxjs/operators';
import {NewOfferDialogComponent} from '../new-offer-dialog/new-offer-dialog.component';
import {StoreService} from 'src/app/_services/store.service';
import {AlertsMessagesComponent} from 'src/app/alerts-messages/alerts-messages.component';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-offer-list-message-table',
  templateUrl: './offer-list-message-table.component.html',
  styleUrls: ['./offer-list-message-table.component.scss']
})
export class OfferListMessageTableComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['name', 'created_at', 'total_redeemed', 'discounts', 'net_sales', 'status', 'action'];

  datasource: OffersV2DataSource;

  @ViewChild(MatPaginator,{static: false}) paginator: MatPaginator;
  @ViewChild(MatSort,{static: false}) sort: MatSort;

  @Input() store:Store;
  @Input() refresh: Subject<boolean> = new Subject<boolean>();

  dataSource
  selectedRow
  search;
  startDate;
  endDate;

  constructor(private storeService: StoreService, public dialog: MatDialog) { }

  ngOnInit() {
    this.dataSource = new OffersV2DataSource(this.storeService)
    this.storeService.current.subscribe(store =>{
      if(store){
        this.dataSource.loadOfferList(+store.id);
      }
    })

    this.refresh.subscribe(data => {
      setTimeout(() => {
        console.log('Time out COnsole');
        this.loadOfferList()

      },500)
    })
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadOfferList()))
      .subscribe();
  }

  loadOfferList() {
    this.dataSource.loadOfferList(+this.store.id, this.search, this.startDate, this.endDate, this.sort.active,
      this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize);
  }

  onRowClicked(row){}

  edit(element) {
    const dialogRef = this.dialog.open(NewOfferDialogComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'offer-v2-dialog',
      data: {
        store: this.store,
        offer: element
      }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      this.refresh.next(true);
    });
  }

  delete(element){
    let dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      minHeight: '20vh',
      data: {
        message: "Are you sure to delete?",
        for_which: 'confirm'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.storeService.deleteOfferV2(element.id).subscribe(data => {
          this.loadOfferList();
        })
      }
    });
  }
}
