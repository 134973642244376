<h1 mat-dialog-title *ngIf="data.img_value =='profile'">Upload GMB Profile Picture</h1>
<h1 mat-dialog-title *ngIf="data.img_value =='cover'">Upload GMB Cover Image</h1>
<div mat-dialog-content>
    <div class="flex flex-row bg-gray-100 border rounded-md border-gray-300 p-3 items-center justify-between"
        *ngIf="data.img_value =='profile'">
        <span style="margin-right: 14px;">Select Image</span>
        <input #imageInput type="file" accept="image/*" (change)="onImageSelected($event)" />
    </div>
    <div class="flex flex-row bg-gray-100 border rounded-md border-gray-300 p-3 items-center justify-between"
        *ngIf="data.img_value =='cover'">
        <span style="margin-right: 14px;">Select Image</span>
        <input #imageInput type="file" accept="image/*" (change)="onCoverSelected($event)" />
    </div>
</div>
<div class="set-img-error">
    {{ImgErrorShow}}
</div>

<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<div mat-dialog-actions align="end">
    <button mat-stroked-button mat-dialog-close style="margin-right: 10px;">
        Cancel
    </button>
    <button mat-flat-button color="primary" type="button" (click)="giftImageUpload()"
        [disabled]="isLoading || !uploadImage">
        Submit
    </button>
</div>