<span *ngIf="data.type == 'add'">
    <form name="addgmbOrderLink" [formGroup]="addgmbOrderLink" (ngSubmit)="addgmbOrderLinkSubmit()"
        class="fixActionRow">
        <h1 mat-dialog-title>Add GMB Order Online Link</h1>
        <div mat-dialog-content style="overflow: hidden;">
            <div fxLayout="row" class="!mt-4" style="width: 100%;">
                <mat-form-field appearance="fill" style="width: 100%;">
                    <mat-label>Place Action Type</mat-label>
                    <mat-select formControlName="placeActionType">
                        <mat-option value="FOOD_TAKEOUT">FOOD_TAKEOUT</mat-option>
                        <mat-option value="FOOD_DELIVERY">FOOD_DELIVERY</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxLayout="row" class="!mt-4 w-full">
                <div fxLayout="row" class="!mt-4" style="width: 100%;">
                    <mat-form-field appearance="fill" style="width: 100%;">
                        <mat-label>Enter Link</mat-label>
                        <input matInput formControlName="uri" placeholder="" type="text">
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="set-img-error">
            {{errorShow}}
        </div>
        <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
        <mat-dialog-actions align="end" class="button-box-right form-row">
            <button mat-button mat-dialog-close mat-stroked-button>Cancel</button>
            <button mat-button mat-flat-button color="primary" class="apply-btn-colr">Submit</button>
        </mat-dialog-actions>
    </form>
</span>

<span *ngIf="data.type == 'edit'">
    <form name="editgmbOrderLink" [formGroup]="editgmbOrderLink" (ngSubmit)="editgmbOrderLinkSubmit()"
        class="fixActionRow">
        <h1 mat-dialog-title>Update Order Online Link</h1>
        <div mat-dialog-content>
            <div fxLayout="row" class="!mt-4" style="width: 100%;">
                <mat-form-field appearance="fill" style="width: 100%;">
                    <mat-label>Place Action Type</mat-label>
                    <input matInput formControlName="placeActionType"  type="text" readonly>
                </mat-form-field>
            </div>
            <div fxLayout="row" class="!mt-4 w-full">
                <div fxLayout="row" class="!mt-4" style="width: 100%;">
                    <mat-form-field appearance="fill" style="width: 100%;">
                        <mat-label>Enter Link</mat-label>
                        <input matInput formControlName="uri" placeholder="" type="text">
                    </mat-form-field>
                </div>
            </div>
        </div>
        <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
        <mat-dialog-actions align="end" class="button-box-right form-row">
            <button mat-button mat-dialog-close mat-stroked-button>Cancel</button>
            <button mat-button mat-flat-button color="primary" class="apply-btn-colr">Submit</button>
        </mat-dialog-actions>
    </form>
</span>

<span *ngIf="data.type == 'delete'">
    <div>
        <div mat-dialog-content>
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <div class="content">Are you sure you want to delete?</div>
            </div>
        </div>
        <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
        <mat-dialog-actions align="end" class="button-box-right form-row">
            <button mat-button mat-dialog-close mat-stroked-button (click)="onNoClick()">Cancel</button>
            <button mat-button mat-flat-button color="primary" class="apply-btn-colr"
                (click)="mediaDelete()">Submit</button>
        </mat-dialog-actions>
    </div>
</span>