<mat-spinner *ngIf="statusCheckLoading;" class="spinner"></mat-spinner>
<div *ngIf="isLoading; then processingBlock else resultBlock"></div>

<ng-template #processingBlock>
  <!-- <button
    class="px-4 py-2 text-blue-500 font-semibold rounded border border-blue-500 hover:bg-blue-100 transition duration-200 ml-4 mt-[53px]"
    (click)="backClick()">
    Go Back
  </button> -->
  <p class="cardPayment cardPayment-mobile xs:!text-2xl">
    Card Payment In Progress:
  </p>
  <p class="!text-red-600 cardPayment cardPayment-mobile xs:!text-2xl">
    Need to cancel? Please press the cancel button on the card reader.
</p>
  <mat-spinner class="spinner"></mat-spinner>
</ng-template>
<ng-template #resultBlock>
  <div>
    <div *ngIf="result.result_code === 'Approved'; then approvedBlock else notApprovedBlock">
    </div>
    <ng-template #approvedBlock>
      <div *ngIf="!store.feature_flag['manual_print_standard_checkout']">
        <p class="cardPayment cardPayment-mobile">
          Card Payment:
        </p>
        <p class="approving">Approved</p>
        <!-- <p class="codeStyle approved-message">{{result?.response?.response?.transactionResult?.responseMessage}}</p> -->
        <button mat-raised-button class="doneButton  mb-4" color="primary" (click)="doneClick()">
          Ok
        </button>
      </div>
      <!-- WITH PRINT OPTIONS -->
      <div *ngIf="store.feature_flag['manual_print_standard_checkout']">
        <p class="cardPayment cardPayment-mobile cardPaymentNew">Card Payment:</p>
        <p class="approving approvingNew">Approved</p>
        <!-- <p class="codeStyle approved-message">{{result?.response?.response?.transactionResult?.responseMessage}}</p> -->
        <app-manual-print-control [manualData]="manualData" (selectionEvent)="doneClick()"></app-manual-print-control>
      </div>

    </ng-template>
    <ng-template #notApprovedBlock>
      <p class="cardPayment cardPayment-mobile">
        Card Payment:
      </p>
      <p class="notApproving">Not Approved</p>
      <p class="codeStyle">{{ result?.response?.response?.transactionResult?.responseMessage || 'Unable to complete the request. Server did not respond.' }}</p>

      <button mat-raised-button type="button" class="statusButton mb-4" (click)="checkStatus(terminalId, orderHash)">
        Status
      </button>

      <button mat-raised-button class="doneButton mb-4" color="primary" (click)="backClick()">
        Back
      </button>
    </ng-template>
  </div>
</ng-template>