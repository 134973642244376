
<h2 mat-dialog-title>{{alertTitle}}</h2>

<mat-dialog-content *ngIf="alertAddress">
  <p>{{alertAddress}}</p>
  <p>Would you like to proceed anyway?</p>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-flat-button (click)="close(false)">Close</button>
    <button mat-flat-button color="primary" (click)="save(true)" class="apply-btn-colr">Yes</button>
</mat-dialog-actions>
